import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Gates from 'components/layout/Sections/Pages/BramyWjazdowe/Gates'
import Info from 'components/layout/Sections/Pages/BramyWjazdowe/Info'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Bramy',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Bramy',
    },
  ],
}

const BramyWjazdowePage: React.FC<PageProps> = ({ data }) => {
  const HOMEPAGE = data?.wpPage?.bramyWjazdowe
  const PAGE_SEO = data?.wpPage?.seo

  const GATE_TYPES = React.useMemo(
    () =>
      HOMEPAGE?.gateTypeGate.map((item) => ({
        img: item?.gateTypeGateImg.localFile.childImageSharp.gatsbyImageData,
        alt: 'gate-type',
        type: item?.gateTypeGateNameProduct,
        description: item?.gateTypeGateDescription,
        link: `${item?.gateTypeGateLink}/`,
        isNew: false,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={HOMEPAGE.gateImg.localFile.childImageSharp.gatsbyImageData}
        imgDesktop={HOMEPAGE.gateImg.localFile.childImageSharp.gatsbyImageData}
        headingMobile={HOMEPAGE.gateTitle}
        headingDesktop={HOMEPAGE.gateTitle}
        subheading={HOMEPAGE.gateType}
        breadcrumbs={breadcrumbs}
      />
      <Gates gates={GATE_TYPES} />
      <Info text={HOMEPAGE.gateDownDescription} />
    </Layout>
  )
}

export default BramyWjazdowePage

export const query = graphql`
  query BramyWjazdowePage {
    wpPage(slug: { regex: "/bramy/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      bramyWjazdowe {
        gateImg {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gateTitle
        gateType
        gateDownDescription
        gateTypeGate {
          gateTypeGateDescription
          gateTypeGateNameProduct
          gateTypeGateLink
          gateTypeGateImg {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
