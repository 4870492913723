import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

const Section = styled.aside`
  width: 100%;
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.gray400};
  }
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.xxl.min} {
    ${Text} {
      width: 85%;
    }
  }
`

type Props = {
  text: string
}

const Info: React.FC<Props> = ({ text }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <Text
          size={lg ? 16 : 14}
          weight={500}
          themecolor="black"
          align={lg ? 'left' : 'center'}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </StyledContainer>
    </Section>
  )
}

export default Info
