import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Gate = {
  img: ImageDataLike
  alt: string
  type: string
  description: string
  link: string
  isNew?: boolean
}

type Props = {
  gates: Gate[]
}

const Section = styled.section`
  width: 100%;
  padding: 20px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 40px 0 80px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const GatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
`

const GateSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 280px;
    margin: 0 30px 50px 0;

    .gatsby-image-wrapper {
      height: 165px;
    }
  }
`

const GateSingleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  ${({ theme }) => theme.media.lg.min} {
    width: 85%;
  }
`

const Hr = styled.div`
  width: 100%;
  height: 8px;
  margin: 5px 0 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`

const Gates: React.FC<Props> = ({ gates }) => {
  const { lg } = useBreakpoint()

  return (
    <Section title="Bramy Wjazdowe">
      <Container>
        <GatesWrapper>
          {gates.map(({ img, alt, type, description, link, isNew }) => (
            <GateSingleWrapper as={Link} to={link} key={type}>
              <LazyImage src={img} alt={alt} />
              <GateSingleInnerWrapper>
                <Hr />
                <Heading
                  as="h6"
                  size={lg ? 18 : 20}
                  themecolor="black"
                  transform="uppercase"
                  margin={isNew ? '0' : '20px'}
                  align="center"
                  dangerouslySetInnerHTML={{ __html: type }}
                />
                {isNew && (
                  <Text
                    size={16}
                    weight={700}
                    themecolor="primary"
                    transform="uppercase"
                    margin="20px"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: 'NOWOŚĆ' }}
                  />
                )}
                <Text
                  size={14}
                  weight={lg ? 400 : 500}
                  themecolor="black100"
                  align="center"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </GateSingleInnerWrapper>
            </GateSingleWrapper>
          ))}
        </GatesWrapper>
      </Container>
    </Section>
  )
}

export default Gates
